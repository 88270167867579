<template>
  <div>
    <header>
      <div class="basic-header">
        <div
          class="logo center"
          style="display:flex;justify-content:center"
        >
          <img
            src="/logo/LToTHAI.png"
            alt="logo"
            height="35"
          >
        </div>
      </div>
    </header>
    <div>
      <div
        class="container"
      >
        <validation-observer
          ref="loginForm"
          #default="{ invalid }"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >
            <div
              class="row align-items-center mt-1"
              style="padding: 0 10px;"
            >
              <div class="col-md col-sm-12 col-xs-12 mb-1">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required"
                >

                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-input-group-prepend is-text>
                      <i class="icon fad fa-mobile-android" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="userEmail"
                      :state="errors.length > 0 ? false : null"
                      placeholder="เบอร์โทรศัพท์"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div class="col-md col-sm-12 col-xs-12 mb-1">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-input-group-prepend is-text>
                      <i class="icon fad fa-lock-open" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="password"
                      type="password"
                      :state="errors.length > 0 ? false : null"
                      placeholder="รหัสผ่าน"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>

              <div class="col-md col-sm-12 col-xs-12 mb-1">
                <router-link
                  :to="{name : 'auth-forgot'}"
                  class="text-dark"
                >
                  <strong><i class="fa fa-key" /> ลืมรหัสผ่าน?</strong>
                </router-link>
              </div>
              <div class="col-md col-sm-6 col-6 mb-1">
                <router-link
                  :to="{name : 'auth-register'}"
                  class="btn secondary-btn btn-block"
                >
                  <i class="fa fa-user-check" /> สมัครสมาชิก
                </router-link>
              </div>
              <div class="col-md col-sm-6 col-6 mb-1">
                <button
                  type="submit"
                  :disabled="invalid"
                  class="btn btn-secondary btn-block golden-btn"
                >
                  <i class="fa fa-sign-in" /> เข้าสู่ระบบ
                </button>
              </div>
            </div>
          </b-form>
        </validation-observer>
      </div>
      <!-- <a
        data-toggle="modal"
        data-target="#modal-login"
        class="btn btn-bar btn-login-modal"
      >เข้าสู่ระบบ</a>

      <a
        href="/register/partner"
        class="btn btn-primary btn-bar"
      >สมัครสมาชิก</a> -->

    </div>
    <b-overlay
      :show="Show"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <!-- Lotto THAI -->
      <div class="header-bg-content3 mb-2">
        <!-- พิเศษเฉพาะ-->
        <div>
&nbsp;
        </div>
        <div class="container">
          <b-row class="mt-2 text-center">
            <b-col lg="12">
              <div class="text-center header-bg-content mb-1">
                <div class="mb-1 d-flex justify-content-center">
                  <img
                    src="th.svg"
                    alt=""
                    height="20"
                  >
                  <span class="YrVDG">
                    ผลสลากกินแบ่งรัฐบาล {{ ResultThaiDate ? ResultThaiDate : 'วว/ดด/ปปปป' }}
                  </span>
                </div>
                <b-row>
                  <b-col
                    lg="6"
                    cols="12"
                  >
                    <h3>{{ ResultThai.R1 ? ResultThai.R1 : 'xxx' }}</h3>
                    <small>รางวัลที่ 1</small>
                  </b-col>

                  <b-col
                    lg="2"
                    cols="4"
                  >
                    <h3>{{ ResultThai.F3N1 ? ResultThai.F3N1 : 'xxx' }} {{ ResultThai.F3N2 ? ResultThai.F3N2 : 'xxx' }}</h3>
                    <small>เลขหน้า 3 ตัว</small>
                  </b-col>

                  <b-col
                    lg="2"
                    cols="4"
                  >
                    <h3>{{ ResultThai.B3N1 ? ResultThai.B3N1 : 'xxx' }} {{ ResultThai.B3N2 ? ResultThai.B3N2 : 'xxx' }}</h3>
                    <small>เลขท้าย 3 ตัว</small>
                  </b-col>

                  <b-col
                    lg="2"
                    cols="4"
                  >
                    <h3> {{ ResultThai.D2 ? ResultThai.D2 : 'xx' }}</h3>
                    <small>สองตัวล่าง</small>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col
              lg="6"
              cols="12"
            >
              <!-- Lotto THAI -->
              <div class="header-bg-content mb-1">
                <div class="mb-1 d-flex justify-content-center">
                  <img
                    src="https://www.เปิดสอบราชการ.com/img/articles/158634671920200416_154512.png"
                    alt=""
                    height="20"
                  >&nbsp;
                  <span class="YrVDG"> หวย ออมสิน {{ ResultAomDate ? ResultAomDate : 'วว/ดด/ปปปป' }} </span>
                </div>
                <b-row>
                  <b-col
                    lg="6"
                    cols="6"
                  >
                    <h3>{{ ResultAom.U3 ? ResultAom.U3 : 'xxx' }}</h3>
                    <small>3ตัวบน</small>
                  </b-col>

                  <b-col
                    lg="6"
                    cols="6"
                  >
                    <h3>{{ ResultAom.D2 ? ResultAom.D2 : 'xx' }}</h3>
                    <small>2ตัวล่าง</small>
                  </b-col>
                </b-row>
              </div>
            <!-- End Lotto THAI -->
            </b-col>

            <b-col
              lg="6"
              cols="12"
            >
              <div class="header-bg-content mb-1">
                <div class="mb-1 d-flex justify-content-center">
                  <img
                    src="https://www.tode365.com/wp-content/uploads/2021/08/%E0%B8%98%E0%B8%81%E0%B8%AA.png"
                    alt=""
                    height="20"
                  >&nbsp;
                  <span class="YrVDG"> หวย ธกส. {{ ResultTKSDate ? ResultTKSDate : 'วว/ดด/ปปปป' }}</span>
                </div>
                <b-row>
                  <b-col
                    lg="6"
                    cols="6"
                  >
                    <h3>{{ ResultTKS.U3 ? ResultTKS.U3 : 'xxx' }}</h3>
                    <small>3ตัวบน</small>
                  </b-col>

                  <b-col
                    lg="6"
                    cols="6"
                  >
                    <h3>{{ ResultTKS.D2 ? ResultTKS.D2 : 'xx' }}</h3>
                    <small>2ตัวล่าง</small>
                  </b-col>
                </b-row>

              </div>
            <!-- End Lotto THAI -->
            </b-col>

            <b-col
              lg="12"
              cols="12"
            >
              <!-- Lotto ยี่กี -->
              <div class="header-bg-content mb-1">
                <div class="mb-1 d-flex justify-content-center">
                  <img
                    src="assets/themes/front2021/images/yigy_special.png"
                    alt=""
                    height="20"
                  >&nbsp;
                  <span class="YrVDG"> จับยี่กี รอบที่ {{ ResultYGRoundNo }} ประจำวันที่ {{ ResultYGLastDate ? ResultYGLastDate : 'วว/ดด/ปปปป' }}</span>
                </div>
                <b-row>
                  <b-col
                    lg="6"
                    cols="6"
                  >
                    <h3>{{ ResultYGLast.U3 ? ResultYGLast.U3 : 'xxx' }}</h3>
                    <small>3ตัวบน</small>
                  </b-col>

                  <b-col
                    lg="6"
                    cols="6"
                  >
                    <h3>{{ ResultYGLast.D2 ? ResultYGLast.D2 : 'xx' }}</h3>
                    <small>2ตัวล่าง</small>
                  </b-col>
                </b-row>
              </div>
            <!-- End Lotto THAI -->
            </b-col>
          </b-row>

          <!-- ---- -->
          <div class="section-wrap section-normal">
            <div class="mb-1 mt-2">
              <strong class="font-medium-2">หวยหุ้นต่างประเทศ ประจำวันที่ {{ dateshow }}</strong>
            </div>
            <div class="row gutters-10">
              <div
                v-for="(item) in list"
                :key="item"
                class="col-md-4 col-lg-2"
              >
                <div class="box-lotto">
                  <div class="box-title">
                    <img
                      :src="`/assets/themes/front2021/images/${item.picture}`"
                      class="img-title"
                    ><strong>{{ item.name }}</strong>
                  </div>
                  <div class="box-inner">
                    <div class="inner-col">
                      <div class="sub-text">
                        สามตัวบน
                      </div>
                      <div
                        v-if="item.result1 > 0"
                        class="lot-num"
                      >
                        {{ item.result1 }}
                      </div>
                      <div
                        v-else
                        class="lot-warning"
                      >
                        <i class="fas fa-exclamation-triangle" />รอผล
                      </div>
                    </div>
                    <div class="inner-col">
                      <div class="sub-text">
                        สองตัวล่าง
                      </div>
                      <div
                        v-if="item.result2 > 0"
                        class="lot-num"
                      >
                        {{ item.result2 }}
                      </div>
                      <div
                        v-else
                        class="lot-warning"
                      >
                        <i class="fas fa-exclamation-triangle" />รอผล
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ยี่กี -->
          <div class="section-wrap section-normal mt-1">
            <div class="mb-1 mt-2">
              <strong class="font-medium-2">จับยี่กี ประจำวันที่ {{ ResultYGTodayDate }}</strong>
            </div>
            <div class="row gutters-10">
              <div
                v-for="(item , index) in ResultYGToday"
                :key="item"
                class="col-md-4 col-lg-2"
              >
                <div class="box-lotto">
                  <div class="box-title yigy264">
                    <img
                      src="assets/themes/front2021/images/yigy_special.png"
                      class="img-title"
                    ><strong>จับยี่กี รอบที่ {{ index + 1 }}</strong>
                  </div>
                  <div class="box-inner">
                    <div class="inner-col">
                      <div class="sub-text">
                        สามตัวบน
                      </div>
                      <div
                        v-if="item.U3 > 0"
                        class="lot-num"
                      >
                        {{ item.U3 }}
                      </div>
                      <div
                        v-else
                        class="lot-warning"
                      >
                        <i class="fas fa-exclamation-triangle" />รอผล
                      </div>
                    </div>
                    <div class="inner-col">
                      <div class="sub-text">
                        สองตัวล่าง
                      </div>
                      <div
                        v-if="item.D2 > 0"
                        class="lot-num"
                      >
                        {{ item.D2 }}
                      </div>
                      <div
                        v-else
                        class="lot-warning"
                      >
                        <i class="fas fa-exclamation-triangle" />รอผล
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    <!-- พิเศษเฉพาะ -->
    </b-overlay>
  </div>
  <!-- End Lotto THAI -->
</template>

<script>
import {
  // BButton,
  BRow, BCol,
  BOverlay,
  BIconController,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BForm,
} from 'bootstrap-vue'
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import HomeBanner from './HomeBanner.vue'
// import HomeSponsor from './HomeSponsor.vue'
import 'swiper/css/swiper.css'
import momenttz from 'moment-timezone'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    // BButton,
    // HomeBanner,
    // HomeSponsor,
    BForm,
    BRow,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BCol,
    BOverlay,
    BIconController,
    ValidationProvider,
    ValidationObserver,
    // BTable,
    // BAvatar,
    // BBadge,
  },
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      Ip: null,
      LottoList: [
        { text: 'กรุณาเลือกหวย', value: null, SubList: null },
        { text: 'หวยรัฐบาล', value: 'THG', SubList: null },
        {
          text: 'ธกส',
          value: 'BAAC',
          SubList: [
            { text: 'หวย ธกส.(BAAC1)', value: 'BAAC1' },
            { text: 'หวย ธกส.(BAAC2)', value: 'BAAC2' },
          ],
        },
      ],
      // sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
      slide: 0,
      Show: true,
      sliding: null,
      date: momenttz().format('YYYY-MM-DD'),
      dateshow: null,
      datechange: null,
      ResultThai: {},
      ResultThaiDate: null,
      ResultAom: {},
      ResultAomDate: null,
      ResultTKS: {},
      ResultTKSDate: null,
      list: [],
      ResultYGLast: {},
      ResultYGLastDate: null,
      ResultYGRoundNo: null,
      ResultYGToday: {},
      ResultYGTodayDate: momenttz().format('DD/MM/YYYY'),
      /* eslint-disable global-require */
      swiperData: [
        { img: require('@/assets/images/salon/1.jpg') },
        { img: require('@/assets/images/salon/2.jpg') },
        { img: require('@/assets/images/salon/3.jpg') },
        { img: require('@/assets/images/salon/4.jpg') },
        { img: require('@/assets/images/salon/5.jpg') },
        { img: require('@/assets/images/salon/6.jpg') },
      ],
      /* eslint-disable global-require */

      swiperOptions: {
        slidesPerView: 2,
        spaceBetween: 50,
        pagination: {
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
        },
      },
      fields: [
        { key: 'round', label: 'รอบ' },
        { key: '3', label: '3ตัวบน' },
        { key: '2', label: '2ตัวล่าง' },
      ],
      items: [
        {
          round: 'หุ้นไทยเช้า',
          3: 'xxx',
          2: 'xx',
        },
        {
          round: 'หุ้นไทยเที่ยง',
          3: 'xxx',
          2: 'xx',
        },
        {
          round: 'หุ้นไทยบ่าย',
          3: 'xxx',
          2: 'xx',
        },
        {
          round: 'หุ้นไทยเย็น',
          3: 'xxx',
          2: 'xx',
        },
      ],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },

  async mounted() {
    this.GetIp()
    this.GetData()
    this.GetResultThai(1, null)
    this.GetResultAom(3, null)
    this.GetResultTKS(2, null)
    this.GetResultYGLast()
    this.GetResultYG_Today()
  },

  methods: {
    async GetIp() {
      try {
        const { data: res } = await axios.get('https://api.ipify.org')
        this.Ip = res
      } catch (e) {
        this.Ip = null
      }
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
              Detail: {
                Ip: this.Ip,
                UserAgent: navigator.userAgent,
                Platform: navigator.platform,
              },
            })
            .then(response => {
              const userData = response.data
              useJwt.setToken(response.data.token)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              localStorage.setItem('LottoList', JSON.stringify(this.LottoList))
              this.$ability.update(userData.ability)

              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              // this.$store.commit(
              //   'app-ecommerce/UPDATE_CART_ITEMS_COUNT',
              //   userData.extras.eCommerceCartItemsCount,
              // )

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router
                .replace(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `มหาล๊อตโต ยินดีต้อนครับค่ะ  คุณ${
                        `${userData.fname} ${userData.sname}`
                        || userData.username
                      }`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: '',
                    },
                  })
                })
            })
            .catch(error => {
              this.$bvToast.toast('เบอร์โทรหรือรหัสที่คุณป้อนไม่ถูกต้อง', {
                title: 'ล็อกอินไม่สำเร็จ',
                variant: 'danger',
                solid: true,
              })
              this.$refs.loginForm.setErrors(error.response.data.error)
            })
        }
      })
    },
    async GetData() {
      try {
        const { data: ResData } = await this.$http.get(
          'https://lineapi.rmtlotto.com/api/Reward/LottoList',
        )

        if (ResData) {
          this.Show = false
          this.list = ResData
          this.GetResult()
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetResultThai(LottoHead, LottoSubHead) {
      const params = {
        LottoHead,
        LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://lineapi.rmtlotto.com/api/result/showlast',
          { params },
        )

        if (ResData.success) {
          this.ResultThai = ResData.mes
          this.ResultThaiDate = momenttz(ResData.mes.RoundData.CloseDate).format('DD/MM/YYYY')
          // return ResData.mes
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetResultAom(LottoHead, LottoSubHead) {
      const params = {
        LottoHead,
        LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://lineapi.rmtlotto.com/api/result/showlast',
          { params },
        )

        if (ResData.success) {
          this.ResultAom = ResData.mes
          this.ResultAomDate = momenttz(ResData.mes.RoundData.CloseDate).format('DD/MM/YYYY')
          // return ResData.mes
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetResultTKS(LottoHead, LottoSubHead) {
      const params = {
        LottoHead,
        LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://lineapi.rmtlotto.com/api/result/showlast',
          { params },
        )

        if (ResData.success) {
          this.ResultTKS = ResData.mes
          this.ResultTKSDate = momenttz(ResData.mes.RoundData.CloseDate).format('DD/MM/YYYY')
          // return ResData.mes
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetResult() {
      this.dateshow = momenttz(this.date).format('DD/MM/YYYY')
      if (this.datechange) {
        this.date = this.datechange
      }

      // eslint-disable-next-line no-restricted-syntax
      for await (const item of this.list) {
        const params = {
          LottoHead: item.HeadID,
          date: this.date,
        }
        try {
          const { data: ResData } = await this.$http.get(
            'https://lineapi.rmtlotto.com/api/result/GetResultReward',
            { params },
          )
          if (ResData.success) {
            let Index = null
            // console.log(ResData.mes)
            Index = this.list.findIndex(
              ({ HeadID }) => HeadID === ResData.mes.LottoHead,
            )
            this.list[Index].result1 = ResData.mes.U3
            this.list[Index].result2 = ResData.mes.D2
          }
        } catch (e) {
          console.log(e)
          // return null
        }
      }
    },
    async GetResultYGLast() {
      try {
        const { data: ResData } = await this.$http.get(
          'https://lineapi.rmtlotto.com/api/result/ShowLastYG',
        )

        if (ResData.success) {
          this.ResultYGLast = ResData.mes
          this.ResultYGRoundNo = ResData.RoundNo
          this.ResultYGLastDate = momenttz(ResData.mes.RoundData.CloseDate).format('DD/MM/YYYY')
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetResultYG_Today() {
      try {
        const { data: ResData } = await this.$http.get(
          'https://lineapi.rmtlotto.com/api/result/YGToDay',
        )

        if (ResData.success) {
          this.ResultYGToday = ResData.mes
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style scoped>
header {
  background-color: #fff;
  padding: 5px 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  /* border-bottom: 1px solid #1391b8; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}
.header-bg-content3 {
  background-color: #ffffff;
  /* border: 1px solid #1391b8; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px;
  box-shadow: 2px 2px 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}
.header-bg-content {
  background-color: #ffffff;
  border: 1px solid #1391b8;
  border-radius: 10px;
  padding: 15px;
  box-shadow: #8f8f8f 0px 0px 10px;
}
.header-bg {
  background-color: rgb(255, 255, 255);
  padding: 4px;
}
.YrVDG {
  font-size: 1.2rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgb(36, 36, 36);
}
@media (max-width: 574px) {
  .YrVDG {
    font-size: 1.1rem;
    font-weight: 700;
  }
}
.text-card {
  font-weight: bold;
  color: #000;
  margin-top: 5px;
}
.container-spon {
  margin: auto;
  max-width: 640px;
}

.bg-balance {
  padding: 10px 25px 10px 25px;
  border: 1px solid rgb(255, 0, 0);
  border-radius: 10px;
  box-shadow: 0px 3px 10px rgb(255, 110, 110);
}

.bg-balance2 {
  border: 1px solid rgb(255, 0, 0);
  border-radius: 10px;
  background-image: url(/images/bg-header.svg);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  border-radius: 9px;
}

.btn-menu {
  border: 1px solid rgb(255, 123, 123);
  padding: 15px;
  border-radius: 10px;
  color: #fff;
  border-radius: 10px;
  background-color: #b62525;
}

.lotto {
  /* text-align: center; */
  border: 1px solid rgb(225, 225, 225);
  border-radius: 5px;
  padding: 0.3rem;
}
.golden-btn {
    display: inline-block;
    outline: none;
    font-weight: bold;
    font-size: 1em;
    box-sizing: border-box;
    border-radius: 0.2em;
    height: 2.7em;
    line-height: 1.9em;
    text-transform: uppercase;
    padding: 3px 0;
    box-shadow: 0 3px 6pxrgba(0,0,0,.16),0 3px 6pxrgba(110,80,20,.4),inset 0 -1px 2px 1px #8b4208,inset 0 -1px 1px 2px #fae385;
    background-image: linear-gradient(160deg,#a54e07,#b47e11,#fef1a2,#bc881b,#a54e07);
    border: 1px solid #a55d07;
    color: #783205 !important;
    text-shadow: 0 2px 2px #fae385;
    cursor: pointer;
    transition: all .2s ease-in-out;
    background-size: 100% 100%;
    background-position: 50%;
}
.secondary-btn {
    display: inline-block;
    outline: none;
    font-weight: bold;
    font-size: 1em;
    box-sizing: border-box;
    border-radius: 0.2em;
    height: 2.7em;
    line-height: 1.9em;
    text-transform: uppercase;
    padding: 3px 0;
    box-shadow: 0 3px 6pxrgba(0,0,0,.16),0 3px 6pxrgba(110,80,20,.4),inset 0 -1px 2px 1px #8b4208,inset 0 -1px 1px 2px #fae385;
    background: #3b3b3b;
    border: 1px solid #8b4208;
    color: #fff !important;
    cursor: pointer;
    transition: all .2s ease-in-out;
    background-size: 100% 100%;
    background-position: 50%;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
